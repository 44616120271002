import * as React from "react"
import { navigate } from "gatsby"
import { Button } from "reactstrap"

import Layout from "../components/layout"
import Seo from "../components/seo"

const NotFoundPage = () => (
  <Layout>
    <Seo title="404: Not found" />

    <div className="w-100 mt-4 max-width-900px mx-auto">
      <p className="text-l bold">Page not found</p>
      <Button size="lg" color="primary" onClick={() => navigate("/home")}>
        Home
      </Button>
    </div>
  </Layout>
)

export default NotFoundPage
